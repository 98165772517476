@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-x: hidden;
}

* {
  font-family: 'Fira Sans', sans-serif;
  scroll-behavior: smooth;
}

body {
  @apply bg-troo-light
}

#root {
  @apply overflow-x-hidden
}

@layer base {
  .font-outline-2 {
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.1);
  }

  .font-outline-4 {
    -webkit-text-stroke: 4px #18B36A;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}


h1 {
  @apply text-3xl md:text-4xl lg:text-7xl leading-[40px] md:leading-[50px] xl:leading-[80px]
}

h2 {
  @apply text-2xl md:text-3xl lg:text-4xl leading-[38px] md:leading-[48px] xl:leading-[44px]
}

h3 {
  @apply text-xl sm:text-2xl md:text-3xl leading-[30px] md:leading-[40px] xl:leading-[50px]
}

h4 {
  @apply text-lg sm:text-xl md:text-2xl leading-[22px] md:leading-[32px] xl:leading-[42px]
}

h5 {
  @apply text-base sm:text-lg md:text-xl leading-[34px]
}

h6 {
  @apply text-sm sm:text-base md:text-lg leading-[30px]
}

.p-1 {
  @apply text-xs sm:text-sm md:text-base
}

.p-2 {
  @apply text-xs sm:text-sm
}

a {
  @apply whitespace-nowrap text-sm md:text-base
}

.activeNav {
  @apply text-troo-primary border-b border-troo-primary
}

.activeMobileNav {
  @apply text-troo-primary
}

.main-container {
  @apply max-w-[1920px] mx-auto px-[10px] md:px-[60px] xl:px-[80px] 2xl:px-[100px];
}
.main-container-r-0 {
  @apply max-w-[1920px] mx-auto px-[10px] md:px-[60px] xl:px-[80px] 2xl:px-0 2xl:pl-[100px];
}

.main-container-big {
  @apply max-w-[1920px] mx-auto px-[20px] md:px-[50px] xl:px-[100px] 2xl:px-[280px];
}

.main-container-big-l-0 {
  @apply max-w-[1920px] mx-auto pr-[20px] md:pr-[50px] xl:pr-[100px] 2xl:pr-[280px];
}

.main-container-big-r-0 {
  @apply max-w-[1920px] mx-auto pl-[20px] md:pl-[50px] xl:pl-[100px] 2xl:pl-[280px];
}

.divider-dot {
  @apply w-1.5 h-1.5 bg-troo-tertiary rounded-full
}

.divider-line {
  @apply w-48 h-[1px] bg-troo-light
}

.btn-theme-primary {
  @apply md:py-4 md:px-5 px-4 py-3 bg-troo-primary text-troo-light rounded-br-lg rounded-tl-lg text-sm md:text-base items-center gap-3 whitespace-nowrap hover:bg-troo-light border border-transparent hover:border-troo-primary hover:text-troo-primary transition-all duration-150 ease-linear
}
.btn-theme-primary-outlined {
  @apply md:py-4 md:px-5 px-4 py-3 border border-troo-primary text-troo-primary bg-transparent rounded-br-lg rounded-tl-lg text-sm md:text-base items-center gap-3 whitespace-nowrap hover:bg-troo-primary hover:text-troo-light transition-all duration-150 ease-linear
}
.btn-theme-light {
  @apply md:py-4 md:px-5 px-4 py-3 bg-troo-light text-troo-primary rounded-br-lg rounded-tl-lg text-sm md:text-base items-center gap-3 whitespace-nowrap
}
.btn-theme-light-outlined {
  @apply md:py-4 md:px-5 px-4 py-3 border border-troo-light text-troo-light bg-transparent rounded-br-lg rounded-tl-lg text-sm md:text-base items-center gap-3 whitespace-nowrap
}


option {
  @apply text-troo-secondary
}

.accordian-input:active .accordian-main {
  background-color: #EBB977 !important;
}

.height-0,
:where(.collapse > input[type="radio"]) {
  min-height: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}